<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-7">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Human Resource</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >Salary Payroll</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Salary Payroll</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-5 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_salary_payroll')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newSalaryPayroll',
              mode: 'create',
            })
          "
        >New Salary Payroll</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select name="size" v-model="params.pageIndex" @change="searchFilter" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            name="size"
            class="filter-input mr-2 mg-t-5"
            v-model="params.branch"
            @change="searchFilter"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in payrollElements.branch"
              :key="index"
              :value="branch.id"
            >{{ branch.name }}</option>
          </select>
          <select
            name="size"
            class="filter-input mr-2 mg-t-5"
            v-model="params.employee"
            @change="searchFilter"
          >
            <option value>All Employee</option>
            <option
              v-for="(employee, index) in payrollElements.employee"
              :key="index"
              :value="employee.id"
            >{{ employee.name }}</option>
          </select>
          <SearchDayMonthComponent @clicked="monthDayClicked"></SearchDayMonthComponent>
          <div class="mr-2 d-inline-block">
            <label for class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              v-model="params.from_date"
              @change="searchFilter"
            />
            <label for class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              placeholder="To"
              class="filter-input mr-1 mg-t-5"
              v-model="params.to_date"
              @change="searchFilter"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            class="float-right"
            placeholder="Search..."
            v-model="params.searched"
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Receipt No.</th>
              <th class="wd-30p">Employee Name</th>
              <th class="wd-15p">Payment Mode</th>
              <th class="wd-10p">Paid Amt.</th>
              <th class="wd-10p">Type</th>
              <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission(['view_salary_payroll','cancel_salary_payroll'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && payrollLists.length > 0">
            <tr v-for="(payroll, index) in payrollLists" :key="index">
              <th scope="row" class="table-start-item">{{ pageSerialNo+index }}.</th>
              <td>{{payroll.date}}</td>
              <td>
                #{{payroll.receipt_no}}
                <span v-if="payroll.status == 1" class="tx-danger">-Canceled</span>
              </td>
              <td>{{payroll.employee.name}}</td>
              <td>
                {{payroll.payment_method.title}}
                <span v-if="payroll.bank_id">-{{payroll.bank.bank}}</span>
              </td>
              <td>{{payroll.paid_amt}}</td>
              <td>
                <div v-if="payroll.is_advance == 0">Salary</div>
                <div v-else>Advance</div>
              </td>
              <td class="text-center table-end-item" v-if="checkIfAnyPermission(['view_salary_payroll','cancel_salary_payroll'])">
                <a href="javascript:;" v-if="checkSinglePermission('view_salary_payroll')" class="mr-3">
                  <i
                    class="fas fa-eye tx-success"
                    title="View"
                    @click="view('receiptSlip', payroll.id)"
                  ></i>
                </a>
                <!-- <a href="javascript:;" class="mr-3">
                  <i class="fa fa-edit" @click="edit('editSalaryPayroll', payroll.id)"></i>
                </a>
                <a href="javascript:;">
                  <i class="fa fa-trash" @click="drop(payroll.id)"></i>
                </a>-->
                <a href="javascript:;" v-if="checkSinglePermission('cancel_salary_payroll')">
                  <i
                    class="fas fa-times-circle tx-danger"
                    title="Cancel"
                    v-if="payroll.status==0"
                    @click="cancel(payroll.id)"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align:center;">{{error_message}}</td>
            </tr>
          </tbody>
           <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && payrollLists.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <Create @parent-event="getData" />
        <Slip />
      </div>
    </div>
  </div>
</template>
<script>
import Create from "./create";
import Services from "../Services/Services";
import Paginate from "vuejs-paginate";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Slip from "./slip";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      params: {
        pageIndex: 10,
        employee: "",
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
      loading: false,
      error: false,
      error_message:'',
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
    };
  },
  components: {
    Create,
    Slip,
    SearchDayMonthComponent,
    Paginate,
  },
  methods: {
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.pageIndex + 1;
      }
      this.params.offset = (page - 1) * this.params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo=1;
      this.params.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 500),
    searchFilter() {
      this.page = 1;
      this.pageSerialNo=1;
      this.params.offset = 0;
      console.log()
      this.getFilteredDataWithoutDates();
    },
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        employee: this.params.employee,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        employee: this.params.employee,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      Services.getPayrollLists(params)
        .then((res) => {
          this.$store.commit("employees/setPayrollLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.getElements();
        })
        .catch((err) => {
          this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          let error = err.response.data.error_message;
          this.setNotification(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/salary-payroll/${id}`);
    },
    cancel(id) {
      this.$swal({
        title: "Do you really want to cancel this Payroll ?",
        html: `<label>Remarks: </label>
        <textarea id="cancel-remarks" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("cancel-remarks").value;
          // cancel the payin with remarks
          return Services.cancelPayroll(id, { remarks: remarks })
            .then((response) => {
              this.payrollLists.forEach((item, index) => {
                if (item.id == id) {
                  this.payrollLists[index]["status"] = 1;
                }
              });
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    getData() {
      this.getFilteredDataWithoutDates();
    },
    getElements() {
      Services.getPayrollElements()
        .then((res) => {
          this.$store.commit("employees/setPayrollElements", res.data.data);
          this.loading = false;
        })
        .catch((err) => {
          this.error = true;
          this.loading = false;
          let error = err.response.data.error_message;
          this.setNotification(error);
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("employees", ["payrollLists", "payrollElements"]),
  },
  mounted() {
    this.getData();
    this.$store.commit("getData2", `api/branch`);
  },
  watch: {
    // eventMessage(value) {
    //   // if (value.indexOf("deleted success") >= 0) {
    //   //   this.$store.commit("setRemoveList", this.dataId);
    //   // }
    // },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>