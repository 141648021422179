<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newSalaryPayroll' || modalId=='employeeSalaryPayroll'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add New Salary Payroll
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose', 'addNewItem')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="branch"
                              id="branch"
                              class="form-control"
                              v-model="formData.branch_id"
                              :disabled="branchDisabled"
                               @change="payement"
                               required
                            >
                            <option value="" disabled>Select Branch</option>
                              <option
                                v-for="(branch, index) in payrollElements.branch"
                                :value="branch.id"
                                :key="index"
                              >
                                {{ branch.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Receipt No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              id="income_id"
                              class="form-control"
                              placeholder="PAYIN005"
                              v-model="formData.receipt_no"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              value="2077/10/10"
                              name="date"
                              id="date"
                              class="form-control"
                              v-model="formData.date"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Employee *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="employee"
                              id="employee"
                              class="form-control"
                              v-if="modalId == 'employeeSalaryPayroll'"
                              disabled
                              v-model="formData.employee_id"
                              @change="setDue"
                              required
                            >
                             <option value="" disabled>Select Employee</option>
                              <option 
                                v-for="(employee, index) in payrollElements.employee"
                                :value="employee.id"
                                :key="index"
                              >
                                {{ employee.name }}
                              </option>
                            </select>
                            <select
                              name="employee"
                              id="employee"
                              v-else
                              class="form-control"
                              v-model="formData.employee_id"
                              @change="setDue"
                              required
                            >
                            <option value="" disabled>Select Employee</option>
                              <option 
                                v-for="employee in payrollElements.employee"
                                :value="employee.id"
                                :key="employee.id"
                              >
                                {{ employee.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="payment_method"
                              id="payment_method"
                              class="form-control"
                              v-model="formData.payment_method_id"
                              @change="getBanks"
                              required
                            >
                            <option value="" disabled>Select Payment Method</option>
                              <option
                                v-for="(paymentMethod) in payement_methods"
                                :value="paymentMethod.id"
                                :key="paymentMethod.id"
                              >
                                {{ paymentMethod.title}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select name="form_bank"
                              id="bank"
                              class="form-control"
                              v-model="formData.bank_id"
                              :disabled="form_bank"
                              required
                              >
                              <option value="" disabled>Select Bank</option>
                              <option
                                v-for="method in payment_banks"
                                :key="method.id"
                                :value="method.id"
                              >
                              {{method.bank}}
                            </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Transaction ID</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              name="transaction_id"
                              id="transaction_id"
                              placeholder="1547220XD"
                              class="form-control"
                              v-model="formData.txn_id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Total Due</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              name="expense_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              disabled
                              v-model="formData.total_due"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Paid Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="NRP 0.00"
                              v-model="formData.paid_amt"
                              :max="(formData.is_advance == 1)?'':formData.total_due"
                              @change="calculatePaidAmt"
                              min="1"
                              step="any"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Remaining Amt.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="NRP 0.00"
                              v-model="formData.due_amt"
                              disabled 
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Settings</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            type="checkbox"
                            name="expense_type"
                            id="expense_import"
                            value="1"
                            v-model="formData.is_advance"
                            @change="calculatePaidAmt"
                          />
                          <label for="expense_import" class="ml-2"
                            >Advance Payment</label
                          >
                        </div>
                        <div class="group-note">
                          <span>Note: </span> Please Check above if received
                          payment is Advance
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              id="description"
                              style="height: 2.2rem"
                              v-model="formData.description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Slip />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Slip from "./slip";
import Services from "../Services/Services";
export default {
  components:{
    Slip,
  },
  data() {
    return {
      disableSubmitButton:false,
      branchDisabled:false,
      payement_methods:[],
      payment_banks:[],
      form_bank:true,
      formData: {
        id: "",
        employee_id: "",
        branch_id: "",
        date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
        payment_method_id: "",
        bank_id: "",
        txn_id: "",
        paid_amt: "",
        due_amt: "",
        total_due:"",
        is_advance: 0,
        description: "",
        employee: [],
        receipt_no:'',
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataLists2",
      "dataLists3",
      "dataLists5",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("employees",["setPayrollLists","payrollElements"])
  },
  mounted() {
    
  },
  methods: {
    calculatePaidAmt() {
      if(this.formData.is_advance == false){
        this.formData.due_amt = this.formData.total_due - this.formData.paid_amt;
      }else{
        this.formData.due_amt = this.formData.total_due
      }
    },
     getReceiptNoByBranch(){
      if(this.formData.branch_id){
          Services.getReceiptNoByBranch(this.formData.branch_id).then(res=>{
            this.formData.receipt_no = res.data.data;
          }).catch(err=>{     
              console.log(err);
          })
      }
    },
    setDue(){
        this.formData.paid_amt = "";
        this.due_amt = "";
        let emp_id = this.formData.employee_id;
        let details = this.payrollElements.employee.find(function (data) {
          return data.id == emp_id;
        });
       this.formData.total_due = details.closing_due;
    },
    payement(){
      this.getReceiptNoByBranch();
            this.formData.bank_id = "";
            this.form_bank = true;
            if(this.payrollElements.payment_methods.length != 0){
                let details=[];
                let methods = this.formData.branch_id;
                    details = this.payrollElements.payment_methods.filter(function (data) {
                        return data.branch == methods;
                    });
                this.payement_methods= {...details};
                this.formData.payment_method_id = details[0].id;
            }
    },
    getBanks(){
        let payment_bank = this.formData.payment_method_id;
        let values = this.payrollElements.payment_methods.find(function (data) {
            if(data.id == payment_bank){
                return data.title;
            } 
        });  
        if(values.title.toLowerCase() == "bank"){
                if(this.payrollElements.banks.length != 0){
                    this.form_bank = false;
                    let details=[];
                    let methods = this.formData.branch_id;
                        details = this.payrollElements.banks.filter(function (data) {
                            return data.branch == methods;
                        });
                    this.payment_banks= {...details};
                    if(details.length > 0){
                        this.formData.bank_id = details[0].id;
                    }else{
                        this.formData.bank_id ="";
                    }
                }else{
                    this.form_bank = false;
                    this.formData.bank_id = '';
                }
            }
            else{
                this.form_bank = true;
                this.formData.bank_id = '';
            }
    },
    clearData(){
        this.formData.id="";
        this.formData.employee_id="";
        this.formData.branch_id="";
        this.formData.date= new Date().toJSON().slice(0,10).replace(/-/g,'-'),
        this.formData.payment_method_id="";
        this.formData.bank_id="";
        this.formData.txn_id="";
        this.formData.paid_amt="";
        this.formData.due_amt="";
        this.formData.total_due="";
        this.formData.is_advance= 0,
        this.formData.description="";
        this.formData.employee="";
        this.formData.receipt_no="";
        this.branchDisabled=false;
        this.disableSubmitButton=false;
    },
    submit(){
        // this.$store.commit("setApiUrl", "api/salary-payroll/store");
        // this.$store.commit("addData", this.formData);
        this.disableSubmitButton = true;
        Services.createNewPayroll(this.formData).then(res=>{
            this.$store.commit("setDataId",res.data.data.id);
            this.$store.commit("employees/setPayrollLists",res.data.data.data);
            this.$store.dispatch("modalWithMode", { id: 'receiptSlip',mode:"read"});
            this.$emit('parent-event');
            // this.$store.dispatch("modalClose");
            // this.setNotification(res.data.success_message);
        }).catch(err=>{
            this.error=false;
            console.log(err);
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
        }).finally(()=>{
          this.loading = false;
          this.disableSubmitButton =false;
        });
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  watch: {
    modalId(value) {
      if(value == 'editSalaryPayroll'){
        this.formData = this.dataLists.find(function (data) {
        return data.id == value;
      });
      this.formData.payment_method_id = this.formData.payment_method.id;
      this.payement();
      this.getBanks();
      }
    },
    modalMode(value) {
      if (value == "create" || value == "edit") {
        this.clearData();
      }
      if(this.modalId == 'employeeSalaryPayroll'){
          Services.getPayrollElements().then(res=>{
              this.$store.commit("employees/setPayrollElements",res.data.data);
              if(res.data.data.branch.length == 1){
                this.branchDisabled = true;
                this.formData.branch_id = res.data.data.branch[0].id;
                this.payement();
              }
              this.formData.employee_id = this.dataId;
              this.setDue();
          }).catch(err=>{ 
              let error = err.response.data.error_message;
              this.setNotification(error);
          });
      }
      if(this.modalId == 'newSalaryPayroll'){
         Services.getPayrollElements().then(res=>{
              this.$store.commit("employees/setPayrollElements",res.data.data);
              if(res.data.data.branch.length == 1){
                this.branchDisabled = true;
                this.formData.branch_id = res.data.data.branch[0].id;
                this.payement();
              }
          }).catch(err=>{ 
              let error = err.response.data.error_message;
              this.setNotification(error);
          });
      }
    },
  },
};
</script>